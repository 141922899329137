import Vue from 'vue'
import Vuex from 'vuex'
import drawerComp from "@/store/drawerComp";
import defaultInfo from "@/store/defaultInfo";
import imageLayoutComp from "@/store/imageLayoutComp";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    modules: {
        drawerComp: drawerComp,
        defaultInfo: defaultInfo,
        imageLayoutComp: imageLayoutComp
    }
})