import {defaultURL} from "@/constants";

const urlPrefix = `${defaultURL}/api/image`
const firstName = "bottle"
const secondName = "scenery"
const thirdName = "people"

export default {
    namespaced: true,
    state: {
        // 默认展示的图片列表，内容为https地址（上方两个）
        displayImageList: [
            {
                url: `${urlPrefix}/${firstName}/1687453704966.png`,
                srcList: [
                    `${urlPrefix}/${firstName}/1687453704966.png`,
                    `${urlPrefix}/${firstName}/1687453756703.png`,
                    `${urlPrefix}/${firstName}/1687698832109.png`,
                    `${urlPrefix}/${firstName}/1687707613101.png`
                ]
            },
            {
                url: `${urlPrefix}/${secondName}/1687783703040.png`,
                srcList: [
                    `${urlPrefix}/${secondName}/1687783703040.png`,
                    `${urlPrefix}/${secondName}/1687783293291.png`,
                    `${urlPrefix}/${secondName}/1687700328630.png`
                ]
            }
        ],
        // 默认展示的图片列表，内容为https地址（下方一个）
        displayImageList1: {
            imgUrl: `${urlPrefix}/${thirdName}/1687453634052.png`,
            imgSrcList: [
                  `${urlPrefix}/${thirdName}/1687453634052.png`,
                  `${urlPrefix}/${thirdName}/1687453626826.png`,
                  `${urlPrefix}/${thirdName}/1687453407686.png`
              ]
        },
        // AI画出的图片，内容为new Blob()
        memoryImageList: [
            {
                url: '',
                srcList: [], // {url: Blob对象, imgType: 'blob'} | {url: 图片路径, imgType: 'url'}
                initIndex: 0, // 初始预览的索引
                imgType: 'blob' // 图片类型：blob | url
            }
        ]
    },
    mutations: {
        putMemoryImage(state, imgObj) {
            let img = imgObj["img"]
            let imgType = imgObj["imgType"]
            // 待添加判断：imgBlob是Blob对象
            // 待添加多个ai预览图片的逻辑, 现在都添加到第一个图片块中
            state.memoryImageList[0].url = img
            state.memoryImageList[0].srcList.push({url: img, imgType: imgType})
            state.memoryImageList[0].imgType = imgType
        }
    },
    getters: {
        getMemoryImageList(state) {
            // console.log('当前列表状态：', state.memoryImageList)
            // 现在只支持第一个图片块
            let urlImage = {};
            // 1.设置默认显示的图片
            if (state.memoryImageList[0].url && state.memoryImageList[0].url !== '') {
                if (state.memoryImageList[0].imgType === 'blob') {
                   urlImage.url = window.URL.createObjectURL(state.memoryImageList[0].url)
                } else if (state.memoryImageList[0].imgType === 'url') {
                   urlImage.url = state.memoryImageList[0].url
                }
            } else {
                return []
            }
            // 2.设置预览图片列表
            if (state.memoryImageList[0].srcList.length !== 0) {
                urlImage.srcList = state.memoryImageList[0].srcList.map(item => {
                    if (item.imgType === 'url') {
                        return item.url
                    } else {
                        return window.URL.createObjectURL(item.url)
                    }
                })
                // 3.设置初始预览图片的索引
                urlImage.initIndex =state.memoryImageList[0].srcList.length - 1
            }
            return [urlImage]
        }
    }
}