<style scoped>
  .image-layout-comp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image-display {
    width: 70%;
    /*background: #42b983;*/
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .image-ai {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img {
    border-radius: 20px; /*圆角弧度*/
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2); /*阴影*/

  }
</style>

<template>
  <div class="image-layout-comp">
<!--    默认展示的图片-->
    <div class="image-display">
      <el-image class="img"
        v-for="(item, index) in displayImageList"
        :key="index"
        style="width: 100px; height: 100px"
        :src="item.url"
        :preview-src-list="item.srcList">
        <template #placeholder>
          <div>正在加载中<span class="dot">...</span></div>
        </template>
      </el-image>
    </div>
    <el-divider></el-divider>
<!--    AI画出的图片-->
    <div class="image-ai">
      <div v-if="isShow" class="new-image-ai">
        <el-image class="img"
            v-for="(item, index) in memoryImageList"
            :key="index"
            style="width: 300px; height: 300px"
            :src="item.url"
            :preview-src-list="item.srcList"
            :initial-index="item.initIndex">
          <template #placeholder>
            <div>正在加载中<span class="dot">...</span></div>
          </template>
        </el-image>
      </div>
      <div v-else class="default-image-ai">
        <el-image class="img"
          style="width: 300px; height: 300px"
          :src="displayImageList1.imgUrl"
          :preview-src-list="displayImageList1.imgSrcList">
          <template #placeholder>
            <div>正在加载中<span class="dot">...</span></div>
          </template>
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageLayoutComp",
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    displayImageList() {
      return this.$store.state.imageLayoutComp.displayImageList
    },
    displayImageList1() {
      return this.$store.state.imageLayoutComp.displayImageList1
    },
    // 计算属性自带监听的功能，当this.$store.getters['imageLayoutComp/getMemoryImageList']的内容变化了，会重新更新值
    memoryImageList: {
      get() {
        // console.log('当前存储列表', this.$store.getters['imageLayoutComp/getMemoryImageList'])
        return this.$store.getters['imageLayoutComp/getMemoryImageList']
      },
      // set(value) {
      //   // this.memoryImageList = value
      // }
    }
  }
}
</script>