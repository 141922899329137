<style scoped>
  h3 {
    letter-spacing: 2px;
  }
  .index-ai-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title-container {
    text-align: center;
  }
  /*padding: 上/下 左/右*/
  .img-button-text-container {
    padding: 5% 2%;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
  }
  .text-button-container {
    margin-top: 3%;
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-container {
    margin-top: 2%;
  }
  .footer-container {
    margin-top: 1%;
    text-align: center;
  }
  .font {
    color: #808080;
    font-size: 14px;
  }
</style>

<template>
  <div class="index-ai-main">
<!--    AI提示 暂未用到-->
    <prompt-comp v-if="promptShow" v-model="promptShow"></prompt-comp>
<!--    高级选项-->
    <advanced-option-comp v-if="advancedOptionShow" v-model="advancedOptionShow"></advanced-option-comp>
<!--    标题区域-->
    <div class="title-container">
      <h3>AI眼中的世界</h3>
    </div>
<!--    主体部分-->
    <div class="img-button-text-container">
<!--    图片区域-->
      <image-layout-comp :is-show="isImageLayout"></image-layout-comp>
<!--    按钮和文字输入区域-->
      <div class="text-button-container">
      <div class="button-container">
        <el-button size="small" @click="getChatGPT">AI提示</el-button>
        <el-button size="small" @click="advancedOptionShow = true">高级选项</el-button>
        <el-button
            type="success"
            plain
            size="small"
            @click="getImage"
        >AI绘画</el-button>
        <el-button
            type="primary"
            plain
            size="small"
            @click="textarea = ''"
        >清除</el-button>
      </div>
<!--     文字输入区域-->
      <div class="text-container">
         <el-input
          type="textarea"
          size="medium"
          maxlength="999"
          show-word-limit
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="悄悄告诉AI想画的内容（AI记忆力很差，记得长按保存图片）"
          v-model="textarea">
        </el-input>
      </div>
    </div>
    </div>

<!--    底部文字区域-->
    <div class="footer-container">
      <el-divider><span class="font">记得长按保存图片</span></el-divider>
      <br>
      <span class="font">羊羊小栈</span>
      <el-divider direction="vertical"></el-divider>
      <span class="font">为简单学习而生</span><br>
      <el-link type="info" href="https://beian.miit.gov.cn/" target="_blank"><span class="font">冀ICP备2023020297号-1</span></el-link>
    </div>
  </div>
</template>

<script>
import imageAiApi from "../api/imageApi"
import ChatApi from "@/api/chatApi";
import commonApi from "@/api/commonApi";
import AdvancedOptionComp from "./advancedOptionComp"
import ImageLayoutComp from "./ImageLayoutComp"
import PromptComp from "@/components/PromptComp";
export default {
  components: {AdvancedOptionComp, ImageLayoutComp, PromptComp},
  data() {
    return {
      // 出错用户看到的内容：todo 待提取到公共变量中
      errMsg: "AI跑去偷懒了，请稍后再试哦~",
      isImageLayout: false, // 控制是否开启ai画图列表
      // ----- AI提示组件 ------
      promptShow: false,
      // ----- 高级组件 -----
      advancedOptionShow: false,
      textarea: '夜晚，海滩，荧光色的沙子，发光的瓶子，天空有闪耀的星星',
    }
  },
  methods: {
    // AI绘图
    async getImage() {
      if (this.textarea === '') {
        this.$message.warning("悄悄告诉AI，你想画什么吧...")
        return
      }

      // 选择模型
      let modelType = localStorage.getItem('modelType') || this.$store.state.defaultInfo.modelType

      // 加载中指令
      const loading = this.$loading({
          lock: true,
          text: '正在疯狂画图中~',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

      try {
        // 获取图片
        if (modelType === 'po') {
            await this.getImageFromPo()
        } else if (modelType === 'ag') {
            await this.getImageFromAg()
        }

        // 转换图片列表
        this.isImageLayout = true
      } catch (err) {
        console.log("请求AI绘图出错了", err)
        this.$message.error(this.errMsg)
      } finally {
        // 关闭加载
        loading.close();
      }
    },
    async getImageFromPo() {
      // 从缓存中读取出参数值 todo 待修改，直接写从缓存中读值
      let paramsObj = JSON.parse(localStorage.getItem("poModelParams")) || this.$store.state.defaultInfo.poModelParams
      let filter = paramsObj["filter"]
      let enhanceType = paramsObj["enhanceType"]
      let seedMode = paramsObj["seedMode"]
      let shape = paramsObj["shape"]
      let curSeed = paramsObj["seedNum"]


      // 根据类型选择随机种子
      let newSeed = 0;
      if (seedMode === 'fixed') {
          newSeed = curSeed
      } else if (seedMode === 'seq') {
        if (curSeed >= this.$store.state.defaultInfo.seedMax) curSeed = 0
        newSeed = curSeed + 1
        let paramsObj = {
          filter: filter,
          shape: shape,
          seedMode: seedMode,
          seedNum: newSeed,
          enhanceType: enhanceType
        }
        // 另一个刷新缓存在PoModelOption中
        localStorage.setItem("poModelParams", JSON.stringify(paramsObj))
      } else if (seedMode === 'random') {
          newSeed = Math.floor(Math.random() * this.$store.state.defaultInfo.seedMax) + 1;
          let paramsObj = {
              filter: filter,
              shape: shape,
              seedMode: seedMode,
              seedNum: newSeed,
              enhanceType: enhanceType
          }
          // 另一个刷新缓存在PoModelOption中
          localStorage.setItem("poModelParams", JSON.stringify(paramsObj))
      }

      // 根据类型选择图片尺寸
      let sizeObj = this.$store.state.defaultInfo.sizes[shape]
      let height = sizeObj["height"];
      let width = sizeObj["width"];

      // 组织请求数据
      let data = {
        query: this.textarea,
        filter_type: filter,
        width: width,
        height: height,
        seed: newSeed,
        enhance_type: enhanceType
      }
      let imgArraybuffer = await imageAiApi.getImageFromPo(data)
      // 将二进制数据转换为Blob对象
      const blob = new Blob([imgArraybuffer], { type: 'image/png' }); // png使用无损压缩，jpeg使用有损压缩
      // // 将图片添加到DOM中
      // this.imgSrc = window.URL.createObjectURL(blob);
      // 暂存blob对象到store内存中
      this.$store.commit('imageLayoutComp/putMemoryImage', {"img": blob, "imgType": 'blob'});
    },
    async getImageFromAg() {
      // 组织请求数据
      let data = {
        query: this.textarea
      }
      let res = await imageAiApi.getImageFromAg(data)
      this.$store.commit('imageLayoutComp/putMemoryImage', {"img": res.data, "imgType": 'url'});
    },
    async getChatGPT() {
      // this.$message.success("AI正在学习中，敬请期待~")
      // this.promptShow = true

      // 加载中指令
      const loading = this.$loading({
          lock: true,
          text: '画些什么好呢，让我想想~',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      try {
        let res = await ChatApi.getRandomDesc()
        // console.log('随机描述：', res)
        this.textarea = res.data['random_desc']
      } catch (err) {
        console.log("生成随机画面描述出错了", err)
        this.$message.error(this.errMsg)
      } finally {
        // 关闭加载
        loading.close();
      }
    }
  },
  mounted() {
    commonApi.visitCount(); // 统计用户访问页面的次数
  },
}
</script>