import request from "./request";

const commonApi = {
    // 统计用户访问页面次数
    async visitCount() {
      return await request({
          url: "/api/visit_count",
          method: "GET"
      })
    }
}

export default commonApi;
