import axios from 'axios'
import {defaultURL} from "@/constants";

// axios.defaults.baseURL = "http://localhost:4329"
// axios.defaults.baseURL = "http://124.221.0.10:5000"
axios.defaults.baseURL = defaultURL
axios.defaults.timeout = 100000

// 前置拦截
axios.interceptors.request.use(config => {
    return config
})

// 后置拦截
axios.interceptors.response.use(response => {
    // console.log('后置拦截：', response)
    // 返回结果为图片二进制内容
    if (response.config.responseType === "arraybuffer") {
        return response.data
    }

    if (response.data.code === 200) {
        return response.data
    } else {
        return Promise.reject(response)
    }
}, error => {
    // console.log("axios.js后置拦截错误", error)
    return Promise.reject(error)
})

export default axios
