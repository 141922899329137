export default {
    state: {
        // ---- 模型类型 ----
        modelType: 'po', // po, ag
        // ---- po模型参数 ----
        poModelParams: {
            filter: 'SHARPEN',
            shape: 'square',
            seedMode: 'random',
            seedNum: 0,
            enhanceType: 'null'
        },
        seedMin: 0,
        seedMax: 150000,
        sizes: {
            'horizontal': {width: 4096,height: 2800},
            'vertical': {width: 2800,height: 4096},
            'square': {width: 4096, height: 4096},
        },
        // ---- ag模型参数 ----
        agModelParams: {

        }
    },
    mutations: {
    },
    getters: {
    }
}