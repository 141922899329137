<style>
  .drawer-content {
    /*margin: 1%;*/
    /*background: #42b983;*/
    /*padding: 1%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .params-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>

<template>
<!--  https://element-plus.org/zh-CN/component/drawer.html-->
  <div>
    <el-drawer
      title="高级选项"
      :size="drawerSize"
      :visible.sync="tmpShow"
      :direction="direction"
      :show-close="false">
      <div class="drawer-content">
<!--        模型类型-->
        <div class="model-radio">
          <el-radio-group v-model="modelRadio" size="small">
            <el-radio-button v-for="(item, index) in modelTypes" :key="index" :label="item.label">{{ item.text }}</el-radio-button>
          </el-radio-group>
        </div>
        <hr/>
<!--        对应模型的高级选项-->
        <div class="params-container">
          <po-model-option v-if="modelRadio === 'po'"></po-model-option>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import PoModelOption from "@/components/advancedOptionComp/PoModelOption";
export default {
  name: "AdvancedOptionComp",
  components: {PoModelOption},
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'isShow',
    event: 'updateEvent'
  },
  data() {
    return {
      // 子组件不能修改 props 下的变量，所以定义一个临时变量
      tmpShow: this.isShow,
      direction: 'btt',   // ltr">从左往右开  rtl">从右往左开  ttb">从上往下开  btt">从下往上开
      drawerSize: '50%',
      // ------- 模型类型 --------
      modelTypes: [
        {
          label: "po",
          text: "po模型"
        },
        {
          label: "ag",
          text: "ag模型"
        }
      ],
      modelRadio: localStorage.getItem('modelType') || this.$store.state.defaultInfo.modelType
    }
  },
  watch: {
    // 监听 sonValue 临时变量，如果临时变量发生变化，那么通过 $emit 将新的值传递给父组件
    tmpShow(newVal) {
        this.$emit('updateEvent', newVal)
    },
    modelRadio: {
      handler(newVal) {
        localStorage.setItem('modelType', newVal)
      },
      immediate: true
    },
  },
  methods: {
  }
}
</script>