import request from "./request";

const imageAiApi = {
    async getImageFromPo(queryData) {
        return await request({
            url: "/api/get_image_data_from_po",
            method: "POST",
            responseType: 'arraybuffer',
            data: queryData
        })
    },
    async getImageFromAg(queryData) {
        return await request({
            url: "/api/get_image_data_from_ag",
            method: "POST",
            data: queryData
        })
    }
}

export default imageAiApi;
