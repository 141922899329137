<style scoped>

</style>

<template>
  <div>
    <el-dialog
      title="AI提示"
      center
      width="70%"
      :visible.sync="tmpShow">
      <div style="background: antiquewhite; text-align: center">
        <span style="background: aqua">这是一段信息</span>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PromptComp",
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'isShow',
    event: 'updateEvent'
  },
  data() {
    return {
      tmpShow: this.isShow
    }
  },
  watch: {
    tmpShow(newVal) {
      this.$emit('updateEvent', newVal)
    }
  }
}
</script>
