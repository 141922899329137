import request from "./request";

const chatApi = {
    // 一言生成一句话
    async getYiyan() {
      return await request({
          url: "/api/get_yiyan",
          method: "GET"
      })
    },
    // 随机获取一条画面描述
    async getRandomDesc() {
      return await request({
          url: "/api/get_random_desc",
          method: "GET"
      })
    },
    // 对内容进行翻译  data: {"query": "需要进行翻译的语句"}
    async chinese2English(data) {
        return await request({
            url: "/api/chinese_to_english",
            method: "POST",
            data: data
        })
    },
    // 对内容进行细节扩充  data: {"query": "需要进行翻译的语句"}
    async contentEnhance(data) {
        return await request({
            url: "/api/content_enhance",
            method: "POST",
            data: data
        })
    },
}

export default chatApi;
