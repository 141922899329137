<template>
  <div class="app">
      <image-a-i></image-a-i>
  </div>
</template>

<script>
import ImageAI from "@/components/IndexAI";

export default {
  name: 'App',
  components: {
    ImageAI
  }
}
</script>

<style>
.app {
  /*background: #67c23a;*/
  width: 94%;
  height: 94%;
  padding: 3%;
}
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
