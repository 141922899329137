export default {
    state: {
        // 下面的数据暂未用到
        filter: localStorage.getItem('filter') || '原图',
        height: localStorage.getItem('height') || 0,
        width: localStorage.getItem('width') || 0,
        seed: localStorage.getItem('seed') || 0
    },
    mutations: {

    }
}