<template>
  <div class="params-container">
<!--        形状-->
    <div class="shape-radio">
      <el-radio-group v-model="shapeRadio" size="small">
        <el-radio-button v-for="(item, index) in shapes" :key="index" :label="item.label">{{ item.text }}</el-radio-button>
      </el-radio-group>
    </div>
    <hr/>
<!--        滤镜-->
    <div class="filter-radio">
      <el-radio-group v-model="filterRadio" size="small">
        <el-radio-button v-for="(item, index) in filters" :key="index" :label="item.label">{{ item.text }}</el-radio-button>
      </el-radio-group>
    </div>
    <hr/>
<!--        随机种子-->
    <div class="seed-radio">
      <el-radio-group v-model="seedRadio" size="small">
        <el-radio-button v-for="(item, index) in seedModes" :key="index" :label="item.label">{{ item.text }}</el-radio-button>
      </el-radio-group>
      <el-input-number
          size="small"
          v-model="seedNum"
          controls-position="right"
          @change="seedNumChange"
          :min="seedMin"
          :max="seedMax">
      </el-input-number>
    </div>
    <hr/>
<!--        画质增强-->
    <div class="quality-radio">
      <el-radio-group v-model="qualityRadio" size="small">
        <el-radio-button v-for="(item, index) in qualityModes" :key="index" :label="item.label">{{ item.text }}</el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "PoModelOption",
  data() {
    // 数据加载前从缓存读取数据，赋值给对应的值（模拟保留用户的操作习惯）
    let poModelParams = JSON.parse(localStorage.getItem("poModelParams")) || this.$store.state.defaultInfo.poModelParams
    // console.log('模型参数', poModelParams)
    return {
      // ------- 滤镜 -------
      filters: [
        {
          label: "null",
          text: "原图"
        },
        {
          label: "SHARPEN",
          text: "锐化"
        },
        {
          label: "UnsharpMask",
          text: "细节增强"
        },
        {
          label: "SMOOTH",
          text: "柔顺"
        },
        {
          label: "GaussianBlur",
          text: "高斯模糊"
        }
      ],
      filterRadio: poModelParams["filter"] || this.$store.state.defaultInfo.poModelParams["filter"], // 默认选择第一项
      // ------- 图片形状 -------
      shapes: [
        {label: 'horizontal', text:'横图'},
        {label: 'vertical', text:'竖图'},
        {label: 'square', text:'方图'}
      ],
      shapeRadio: poModelParams["shape"] || this.$store.state.defaultInfo.poModelParams["shape"],
      // ------- 随机种子 -------
      seedModes: [{label: 'fixed', text:'固定'}, {label: 'seq', text:'顺序'}, {label: 'random', text:'随机'}],
      seedRadio: poModelParams["seedMode"] || this.$store.state.defaultInfo.poModelParams["seedMode"],
      seedNum: poModelParams["seedNum"] || this.$store.state.defaultInfo.poModelParams["seedNum"],
      seedMin: this.$store.state.defaultInfo.seedMin,
      seedMax: this.$store.state.defaultInfo.seedMax,
      // ------- 画质品质 -------
      qualityModes: [
        {
          label: "null",
          text: "正常"
        },
        {
          label: "trans_and_weak",
          text: "c2e弱"
        },
        {
          label: "trans_and_strong",
          text: "c2e强"
        },
        {
          label: "weak",
          text: "弱"
        },
        {
          label: "strong",
          text: "强"
        }
      ],
      qualityRadio: poModelParams["enhanceType"] || this.$store.state.defaultInfo.poModelParams["enhanceType"]
    }
  },
  // watch: { // 注意：监听的值，先存到内存中，当这个页面关闭的时候，统一写到缓存中
  //   filterRadio: {
  //     handler(newVal) {
  //       this.$store.state.defaultInfo.poModelParams["filter"] = newVal
  //     },
  //     immediate: true
  //   },
  //   shapeRadio: {
  //     handler(newVal) {
  //       this.$store.state.defaultInfo.poModelParams["shape"] = newVal
  //     },
  //     immediate: true
  //   },
  //   seedRadio: {
  //     handler(newVal) {
  //       this.$store.state.defaultInfo.poModelParams["seedMode"] = newVal
  //     },
  //     immediate: true
  //   },
  //   qualityRadio: {
  //     handler(newVal) {
  //       this.$store.state.defaultInfo.poModelParams["enhanceType"] = newVal
  //     },
  //     immediate: true
  //   },
  // },
  methods: {
    // 随机种子具体值变化时触发的操作
    seedNumChange(seedNum) {
      console.log(seedNum)
      // this.$store.state.defaultInfo.poModelParams["seed"] = seedNum
    },
  },
  beforeCreate() {

  },
  // 组件销毁前向缓存中添加数据
  beforeDestroy() {
    // console.log("父组件销毁前");
    let paramsObj = {
        filter: this.filterRadio,
        shape: this.shapeRadio,
        seedMode: this.seedRadio,
        seedNum: this.seedNum,
        enhanceType: this.qualityRadio
    }
    localStorage.setItem("poModelParams", JSON.stringify(paramsObj))
  },
}
</script>

<style scoped>

</style>